<template>
    <div class="page_content">
        <div class="page_edit_content">
            <Form ref="form" @handleChange="handleChange" @handleSubmit="handleSubmit" @handleReset="handleReset"
                @get_select_value="get_select_value" :form_data="form_data">
                <div slot="merchant_no">
                    {{merchant_id}}
                </div>
                <div slot="card_no">
                    <a @click="add_no()">
                        <a-icon type="plus-circle" />
                        <span style="padding-left: 5px">添加车牌</span>
                    </a>
                    <a-input type="hidden" name="license_data" v-decorator="['license_data', license_data]" />
                    <div>
                        <div v-for="(item, index) in license_data" :key="index">
                            <span>车牌号：</span>
                            <span style="color: #1890ff;margin-right: 15px;">{{item.truck_license}}</span>
                            <a-tag color="#108ee9" v-if='item.type.indexOf(1) != -1'>承运/自提</a-tag>
                            <a-tag color="#FF9319" v-if="item.type.indexOf(2) != -1">统一配送</a-tag>
                            <!-- <span  style="margin-right: 15px;">{{item.type==1?'承运/自提':'统一配送'}}</span> -->
                            <a @click="edit_no(index)" class="time_del" style="margin-right:10px">编辑</a>
                            <a @click="del_no(index)" class="time_del">删除</a>
                            <!-- <a-divider type="vertical" /> -->
                        </div>
                    </div>
                </div>
                <div slot="seller">
                    <a @click="add_seller()">
                        <a-icon type="plus-circle" />
                        <span style="padding-left: 5px">添加经销商</span>
                    </a>
                    <a-input type="hidden" name="seller_data" v-decorator="['seller_data', seller_data]" />
                    <div>
                        <div class="box" v-for="(item, index) in seller_data" :key="index">
                            <div class="seller_box">
                                <div>
                                    <span>经销商名称：</span>
                                    <span style="color: #1890ff">{{
                                        item.seller_name
                                    }}</span>
                                </div>
                                <div>
                                    <span>电话：</span>
                                    <span style="color: #1890ff">{{
                                        item.phone
                                    }}</span>
                                </div>
                                <!-- <div>
                                    <span>经销商类型：</span>
                                    <span style="color: #1890ff">{{
                                        item.seller_type
                                    }}</span>
                                </div> -->
                                <div>
                                    <span>经销商地址：</span>
                                    <span style="color: #1890ff">{{
                                        item.address
                                    }}</span>
                                </div>
                                <a @click="del_seller(index)" class="time_del">删除</a>
                            </div>
                            <!-- <a-divider type="vertical" /> -->
                        </div>
                    </div>
                </div>
            </Form>
        </div>
        <EditPop ref="edit" @handleSubmit="submit_car" :form_data="form_data_seo" :visible.sync="visible"></EditPop>
        <SelectSeller @submit="submit_seller" :visible.sync="visible_seller"></SelectSeller>
    </div>
</template>

<script>
    import EditPop from "@/components/EditPop";
    import SelectSeller from "@/components/SelectSeller";
    import Form from "@/components/Form";
    import {
        get_driver_detail,
        get_carrier_select,
        save_driver
    } from '@/api/push'
    import {
        message,
        Modal
    } from 'ant-design-vue';
    // import { try } from 'q';

    export default {
        components: {
            Form,
            EditPop,
            SelectSeller
        },
        data() {
            return {
                visible: false,
                visible_seller: false,
                info: {},
                merchant_id: '',
                license_data: [],
                seller_data: [],
                form_data: {
                    title: "添加司机",
                    show_submit_btn: true,
                    show_close_btn: true,
                    close_reset_btn: true,
                    list: [{
                            type: "text",
                            name: "id",
                            hidden: true,
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "chauffeur",
                            title: "司机名称",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请输入司机名称"
                                }],
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "chauffeur_mobile",
                            title: "联系电话",
                            placeholder: "请输入联系电话",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请输入联系电话"
                                }],
                                initialValue: ""
                            }
                        },
                        {
                            type: "select",
                            name: "merchant_id",
                            title: "承运商",
                            mode: "default",
                            options: {
                                initialValue: '',
                                rules: [{
                                    required: true,
                                    message: "请选择承运商"
                                }],
                            },
                            list: []
                        },
                        {
                            type: "slot",
                            name: "merchant_no",
                            title: "承运商编号",
                            options: {},
                        },
                        {
                            type: "slot",
                            name: "card_no",
                            title: "车牌",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请添加车牌"
                                }],
                            },
                        },
                        {
                            type: "slot",
                            name: "seller",
                            title: "经销商",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请添加经销商"
                                }],
                            },
                        },
                    ]
                },
                form_data_seo: {
                    title: "添加车牌",
                    list: [{
                            type: "text",
                            name: "truck_license",
                            title: "车牌",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请添加车牌"
                                }],
                            },
                        },
                        {
                            type: "checkbox",
                            list: [{
                                value: '承运/自提',
                                key: 1
                            }, {
                                value: '统一配送',
                                key: 2
                            }],
                            name: "type",
                            title: "车牌类型",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择车牌类型"
                                }]
                            },
                        },
                    ]
                },
            };
        },
        async created() {
            let id = this.$route.query.id;
            if (id) {
                this.get_list();
            }
            this.get_select();
        },
        methods: {
            async get_list(e) {
                let id = this.$route.query.id;
                try {
                    await get_driver_detail({
                        data: {
                            id: id
                        }
                    }).then(res => {
                        let list = res.data.list;
                        this.merchant_id = list.merchant_id
                        this.info = res.data.list
                        this.license_data = list.truck_license.map(item=>{
                            return {
                                id:item.id,
                                type:item.type.split(',').map(item=>item * 1),
                                truck_license:item.truck_license
                            }
                        })
                        this.seller_data = list.seller_info
                        this.form_data.list.forEach(item => {
                            for (let vo in list) {
                                if (item.name == vo) {
                                    if (item.type == "date") {


                                    } else if (item.type == 'select') {
                                        this.$refs.form.setFieldsValue({
                                            [item.name]: list[vo]
                                        })
                                    } else {
                                        if (list[vo] != null) {
                                            item.options.initialValue = list[vo].toString();
                                        }

                                    }
                                } else if (item.type == "slot" && item.name == 'department') {

                                    let data = list.department_data
                                    let select = []
                                    data.forEach(io => {
                                        select.push({
                                            department_id: io.id,
                                            department_name: io.name,
                                            role_id: io.role_data.id,
                                            role_name: io.role_data.name,
                                        })
                                    })
                                    this.department = select
                                    this.$refs.form.setFieldsValue({
                                        department_data: JSON.stringify(this.department)
                                    })
                                }
                            }
                        });
                    });
                } catch {}
                // let list =
            },
            get_select() {
                get_carrier_select().then(res => {
                    let select_data = [];
                    for (let i = 0; i < res.data.list.length; i++) {
                        select_data.push({
                            key: res.data.list[i].value,
                            value: res.data.list[i].label
                        })
                    }
                    this.form_data.list[3]['list'] = select_data
                })
            },
            add_no() {
                this.form_data_seo.title = '添加车牌'
                delete this.form_data_seo.id
                this.form_data_seo.list.forEach(item => {
                    if (item.name == 'truck_license') {
                        delete item.options.initialValue
                    } else if (item.name == 'type') {
                        delete item.options.initialValue
                    }
                })
                this.visible = true;
            },
            add_seller() {
                this.visible_seller = true
            },
            edit_no(index) {
                this.visible = true;
                this.form_data_seo.id = this.license_data[index].id ? this.license_data[index].id : index + 'A'
                this.form_data_seo.title = '编辑车牌'
                this.form_data_seo.list.forEach(item => {
                    if (item.name == 'truck_license') {
                        item.options.initialValue = this.license_data[index].truck_license
                    } else if (item.name == 'type') {
                        item.options.initialValue = this.license_data[index].type
                    }
                })
            },
            del_no(index) {
                // if (index == 0) {
                // 	this.license_data.shift(0)
                // } else {
                // 	this.license_data = this.license_data.slice(0,index)
                // }
                this.license_data.splice(index, 1)
            },
            del_seller(index) {
                // if (index == 0) {
                // 	this.seller_data.shift(0)
                // } else {
                // 	this.seller_data = this.seller_data.slice(0,index)
                // }
                this.seller_data.splice(index, 1)
            },
            get_select_value(data) {
                this.merchant_id = data
            },
            handleChange(data) {

            },
            handleReset() {
                this.form.resetFields();
            },
            handleSubmit(e) {
                let value = e.values
                let license_data = this.license_data
                license_data.forEach(item=>{
                    item.type = item.type.join(',')
                })
                let obj = {
                    ...value,
                    license_data: license_data,
                    seller_data: this.seller_data
                }
                try {
                    save_driver({
                        data: obj,
                        info: true,
                    }).then(res => {
                        this.$router.go(-1)
                    }).catch(error => {

                    })

                } catch {

                }
            },
            submit_car(e) {
                let data = e.values;
                if (this.form_data_seo.id) {
                    let indedx = this.license_data.findIndex(item => item.id == this.form_data_seo.id)
                    let xind = this.license_data.findIndex(item => item.truck_license == data.truck_license)

                    if (indedx > -1) {
                        if (xind != -1 && xind != indedx) {
                            return message.warn('已有相同车牌')
                        }
                        this.license_data[indedx] = {
                            truck_license: data.truck_license,
                            type: data.type,
                            id: this.form_data_seo.id
                        }
                    } else if (this.form_data_seo.id.indexOf('A') != -1) {
                        let index = this.form_data_seo.id.split('A')[0]
                        if (xind != -1 && xind != index) {
                            return message.warn('已有相同车牌')
                        }
                        this.license_data[index] = {
                            truck_license: data.truck_license,
                            type: data.type,
                            id: this.form_data_seo.id
                        }
                    }

                } else {
                    let xind = this.license_data.findIndex(item => item.truck_license == data.truck_license)
                    if (xind != -1) {
                        return message.warn('已有相同车牌')
                    } else {
                        this.license_data.push(data)
                    }
                }

                this.visible = false;
            },
            submit_seller({
                data
            }) {
                this.seller_data.push(data)
                this.visible_seller = false;
            },
        }
    };
</script>
<style lang="less">
    .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .time_del {
            display: block;
            padding-right: 10px;
            min-width: 70px;
        }

        .seller_box {
            display: flex;

            div {
                margin-right: 10px;
            }
        }
    }
</style>